const inventory_positions = {
    name: 'ref_inventory_positions',
    title: 'Виды узлов',
    url: '/inventory_positions',
    include: [],
    filterBy: ['title'],
    perms: {
        list: 'v1.inventory_positions.list',
        read: 'v1.inventory_positions.read',
        create: 'v1.inventory_positions.create',
        update: 'v1.inventory_positions.update',
        delete: 'v1.inventory_positions.delete',
    },
    listColumns: [
        {
            name: 'title',
            required: true,
            label: 'Наименование',
            align: 'left',
            field: 'title',
            sortable: true,
        },
        {
            name: 'inventory_types',
            required: true,
            label: 'Виды узлов',
            align: 'left',
            field: 'inventory_types',
            map: 'title',
            sortable: true,
            type: 'multiple',
        },
    ],
    editColumns: [
        {
            name: 'title',
            required: true,
            label: 'Наименование',
            align: 'left',
            field: 'title',
            sortable: true,
        },
        {
            name: 'inventory_types',
            required: true,
            label: 'Виды узлов',
            align: 'left',
            field: 'inventory_types',
            map: 'title',
            sortable: true,
            type: 'multiple',
        },
    ],
};

export {
    inventory_positions,
};
